
import { mapGetters, mapMutations } from "vuex";
import { Indicator,MessageBox } from 'mint-ui';
import { SmartStorage } from "smart-core-util";
export default {
  computed: {
      ...mapGetters(["event",'address',]),
  },
  methods: {
    ...mapMutations(["ADDRESS", ]), 
    getPosition() {
      return new Promise((resolve, reject) => {
        let eventData = SmartStorage.get("eventData")
          if(SmartStorage.get("tenant_code") == "roche" &&(eventData.typeDictTxt == "科室会" || eventData.typeDictTxt == "院内会")){
              let address = {
                  latitude: eventData.nl,
                  longitude: eventData.el,
                  name: eventData.hospitalName,
                  city: eventData.cityDictVal,
              }
              this['ADDRESS'](address)
              console.log('roche科室会||院内会地址',this.address);
              resolve(true)
              return
          }
          Indicator.open({
              text: '定位中...',
              spinnerType: 'fading-circle'
          });
          let _this = this
          var map = new AMap.Map("container", {});
          AMap.plugin("AMap.Geolocation", () => {
              /* global AMap */
              var geolocation = new AMap.Geolocation({
                  timeout: 3000, //超过n秒后停止定位，默认：5s
                  useNative: true,
                  enableHighAccuracy: true, //是否使用高精度定位，默认:true
                  extensions: 'all'
              });
              map.addControl(geolocation);
              geolocation.getCurrentPosition( async (status, result) => {
                  console.log('定位时会议城市',this.event.cityDictVal);
                  if (status == "complete") {
                    Indicator.close()
                      let city = result.addressComponent.city || result.addressComponent.province;
                      // 定位成功（定位城市与会议城市一致取或者没有会议城市经纬度当前定位）
                      if(city && this.event.cityDictVal == city) {
                          let address = {
                              latitude: result.position.lat,
                              longitude: result.position.lng,
                              name: (result.pois || [{name: ''}])[0].name || result.formattedAddress,
                              city: city
                          }
                          this['ADDRESS'](address)
                          console.log('当前城市在会议城市时',this.address);
                      }else{
                          console.log('当前城市不在会议城市时',city);
                          await this.getLocationFromCity()
                      }
                      if(this.event.search){
                          this['ADDRESS'](this.event.address)
                      }
                  } else {
                      console.log('获取定位失败');
                      await this.getLocationFromCity()
                  }
                  resolve(true)
              })
          })
      })
  },
  getLocationFromCity(){
    //   Indicator.open({
    //       text: '定位中....',
    //       spinnerType: 'fading-circle'
    //   });
      return new Promise((resolve, reject) => {
          //根据会议城市查询经纬度
          var geocoder = new AMap.Geocoder({});
          let _this = this;
          let addressName = _this.event.city || _this.event.cityDictVal
          geocoder.getLocation(addressName,
              function (status2, result2) {
                Indicator.close()
                  if (status2 === "complete" && result2.geocodes.length) {
                      const lnglat = result2.geocodes[0].location;
                      let address = {
                          latitude: lnglat.lat,
                          longitude: lnglat.lng,
                          name: addressName,
                          city: addressName
                      }
                      _this['ADDRESS'](address)
                      console.log('通过会议城市获取经纬度成功',_this.address);
                  }else{
                      // 定位失败且获取会议城市经纬度失败的时候
                      let address = {
                          latitude: '0',
                          longitude: '0',
                          name: addressName,
                          city: addressName
                      }
                      _this['ADDRESS'](address)
                      console.log('通过会议城市获取经纬度失败',_this.address);
                  }
                  resolve(true)
              }
          );
      })
  },
  }
}